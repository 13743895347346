import React, { useState } from 'react';
import Modal from '../../../../global/messages/modal/modal';
import ModalComponents from '../../../../global/messages/modal/modal.components';
import { ModalProps } from '../../../../global/messages/modal/modal.types';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  PostMeetingUpdateAgendaData,
  postMeetingUpdateAgenda,
  getMeetingTemplates,
  GetMeetingTemplatesQuery,
  getDownloadMeetingFile,
} from '../../../../service/api/groupsApi';
import Input from '../../../../form/input/input';
import ButtonIcon from '../../../../global/buttonIcon/buttonIcon';
import DragItem from '../../../pathway/components/dragItem';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ME, MEETINGS_TEMPLATES, MY_GROUPS } from '../../../../service/queryKeys';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorFeedback from '../../../../form/common/error';
import Alert from '../../../../global/alert/alert';
import Selector from '../../../../form/select/selector';
import { MeetingPathway } from '../../../../@types/Entity/MeetingPathway';
import { Option } from '../../../../@types/global';
import { MeetingTemplate } from '../../../../@types/Entity/MeetingTemplate';
import DragAndDropFile from '../../../../form/dragAndDrop/DragAndDropFile';
import { LinkStyled } from '../../../../global/link/link.styled';
import { niotRole } from '../../../../resources/roles';
import { getMe } from '../../../../service/api';
import { isLoggedIn } from '../../../../service/auth';

const BASE_PREREQUISITE = {
  id: null,
  title: '',
};

const BASE_AGENDA_ITEM = {
  id: null,
  title: '',
};

const BASE_RESOURCE = {
  id: null,
  title: '',
  url: '',
};

const VALIDATION_SCHEMA = Yup.object().shape({
  prerequisites: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .max(255, 'This field has a limit of 255 characters.')
        .nullable()
        .required('This field is required.'),
    })
  ),
  agendaItems: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string()
          .max(255, 'This field has a limit of 255 characters.')
          .nullable()
          .required('This field is required.'),
      })
    )
    .min(1, 'There should be at least 1 agenda item.'),
  resources: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .max(255, 'This field has a limit of 255 characters.')
        .nullable()
        .when('type', {
          is: (value?: string) => value !== 'file',
          then: (schema) => schema.required('This field is required.'),
        }),
      url: Yup.string()
        .max(255, 'This field has a limit of 255 characters.')
        .nullable()
        .when('type', {
          is: (value?: string) => value !== 'file',
          then: (schema) => schema.required('This field is required.').url('This is not a valid URL (https://...).'),
        }),
    })
  ),
});

export default function MeetingAgendaFormModal({ toggle, meetingAgendaToEdit, meetingPathway }: Props) {
  const queryClient = useQueryClient();

  const [formInitialData, setFormInitialData] = useState<FormValues>();
  const [usingTemplate, setUsingTemplate] = useState<boolean>(false);

  const hasAgenda =
    0 < meetingAgendaToEdit.prerequisites.length ||
    0 < meetingAgendaToEdit.agendaItems.length ||
    0 < meetingAgendaToEdit.resources.length;

  const getMeetingsTemplatesOptionsQuery = useQuery(
    [MEETINGS_TEMPLATES, { meetingPathway: meetingPathway?.id }],
    () => {
      const query: GetMeetingTemplatesQuery = {};

      if (null !== meetingPathway) {
        query['meetingPathway'] = meetingPathway.id;
      } else {
        query['meetingPathway'] = 'without';
      }

      return getMeetingTemplates(query);
    },
    {
      select: (data) =>
        data.data.map((meetingTemplate) => ({
          label: meetingTemplate.title,
          value: meetingTemplate.id,
          original: meetingTemplate,
        })),
      enabled: !hasAgenda,
    }
  );

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    register,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: { ...meetingAgendaToEdit, files: [] },
    values: formInitialData,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const prerequisitesFieldArray = useFieldArray({
    name: 'prerequisites',
    control: control,
    keyName: 'uniqueId',
  });

  const agendaItemsFieldArray = useFieldArray({
    name: 'agendaItems',
    control: control,
    keyName: 'uniqueId',
  });

  const resourcesFieldArray = useFieldArray({
    name: 'resources',
    control: control,
    keyName: 'uniqueId',
  });

  const postMeetingUpdateAgendaMutation = useMutation(
    ({ id, data }: { id: string; data: PostMeetingUpdateAgendaData }) => postMeetingUpdateAgenda(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MY_GROUPS]);
        toggle();
      },
    }
  );

  const getMeQuery = useQuery([ME], () => getMe(), {
    staleTime: Infinity,
    select: (data) => data.data,
    enabled: isLoggedIn(),
  });

  const me = getMeQuery.data?.data.me ?? null;

  const userTag = niotRole(me);

  return (
    <Modal
      preventCloseOnClickOnMask
      open
      toggle={() => toggle()}
      title={!hasAgenda ? 'Add agenda' : 'Edit agenda'}
      size={'md'}
    >
      <form
        onSubmit={handleSubmit((values) =>
          postMeetingUpdateAgendaMutation.mutate({
            id: meetingAgendaToEdit.id,
            data: {
              prerequisites: values.prerequisites.map((prerequisite, index) => ({ ...prerequisite, index: index })),
              agendaItems: values.agendaItems.map((agendaItem, index) => ({ ...agendaItem, index: index })),
              resources: values.resources.map((resource, index) => ({ ...resource, index: index })),
              files: values.files,
              meetingPurpose: values?.meetingPurpose,
              type: values?.type,
              use_template: usingTemplate,
              template_id: formInitialData?.id ?? '',
            },
          })
        )}
      >
        <ModalComponents.Body>
          {!hasAgenda && (
            <Alert type={'info'} className={'field-mb'} title={'Agenda templates'}>
              You can create your own agenda or select an existing agenda in order to populate the fields below.
              <Selector
                id={'agenda-templates'}
                className={'mt-2'}
                options={getMeetingsTemplatesOptionsQuery.data}
                onChange={(selected: Option<string, MeetingTemplate>) => {
                  setFormInitialData({ ...selected.original, files: [] });
                  setUsingTemplate(true);
                }}
                isLoading={!getMeetingsTemplatesOptionsQuery.isFetched && getMeetingsTemplatesOptionsQuery.isFetching}
                isDisabled={!getMeetingsTemplatesOptionsQuery.isFetched && getMeetingsTemplatesOptionsQuery.isFetching}
              />
            </Alert>
          )}

          <DndProvider backend={HTML5Backend}>
            <div className={'mb-10'}>
              <div className={'flex justify-between mb-4'}>
                <div>
                  <h5>Prerequisites</h5>
                  <p className={'text-sm'}>
                    Add any tasks your {userTag === 'null' ? 'colleagues' : 'mentee'} should complete before the meeting
                  </p>
                </div>
                <div>
                  <ButtonIcon
                    icon={'Plus'}
                    isFree
                    label={'Add prerequisite'}
                    size={'xsm'}
                    onClick={() => prerequisitesFieldArray.append(BASE_PREREQUISITE)}
                  />
                </div>
              </div>
              {prerequisitesFieldArray.fields.map((field, index) => (
                <DragItem
                  key={field.uniqueId}
                  id={field.uniqueId}
                  index={index}
                  moveItem={(draggedId, hoverIndex) => {
                    const draggedIndex = prerequisitesFieldArray.fields.findIndex(
                      (field) => draggedId === field.uniqueId
                    );

                    prerequisitesFieldArray.move(draggedIndex, hoverIndex);
                  }}
                  findItem={(id) => {
                    const index = prerequisitesFieldArray.fields.findIndex((field) => id === field.uniqueId);

                    return {
                      id: id,
                      index: index,
                    };
                  }}
                  removeDisabled={false}
                  handleRemove={() => prerequisitesFieldArray.remove(index)}
                  type={'prerequisites'}
                >
                  <Input
                    {...register(`prerequisites.${index}.title` as const)}
                    id={`prerequisite-title-${field.uniqueId}`}
                    placeholder={'Title'}
                    error={errors.prerequisites?.[index]?.title?.message}
                  />
                </DragItem>
              ))}
            </div>
            <div className={'mb-10'}>
              <div className={'flex justify-between mb-4'}>
                <div>
                  <h5>Agenda items</h5>
                  <p className={'text-sm'}>Add any agenda items for this meeting</p>
                </div>
                <div>
                  <ButtonIcon
                    icon={'Plus'}
                    isFree
                    label={'Add agenda item'}
                    size={'xsm'}
                    onClick={() => agendaItemsFieldArray.append(BASE_AGENDA_ITEM)}
                  />
                </div>
              </div>
              {errors.agendaItems?.message && <ErrorFeedback error={errors.agendaItems?.message} />}
              {agendaItemsFieldArray.fields.map((field, index) => (
                <DragItem
                  key={field.uniqueId}
                  id={field.uniqueId}
                  index={index}
                  moveItem={(draggedId, hoverIndex) => {
                    const draggedIndex = agendaItemsFieldArray.fields.findIndex(
                      (field) => draggedId === field.uniqueId
                    );

                    agendaItemsFieldArray.move(draggedIndex, hoverIndex);
                  }}
                  findItem={(id) => {
                    const index = agendaItemsFieldArray.fields.findIndex((field) => id === field.uniqueId);

                    return {
                      id: id,
                      index: index,
                    };
                  }}
                  removeDisabled={false}
                  handleRemove={() => agendaItemsFieldArray.remove(index)}
                  type={'agendaItems'}
                >
                  <Input
                    {...register(`agendaItems.${index}.title` as const)}
                    id={`agendaItem-title-${field.uniqueId}`}
                    placeholder={'Title'}
                    error={errors.agendaItems?.[index]?.title?.message}
                  />
                </DragItem>
              ))}
            </div>
            <div>
              <div className={'flex justify-between mb-4'}>
                <div>
                  <h5>Resources</h5>
                  <p className={'text-sm'}>Add resources for this meeting</p>
                </div>
                <div>
                  <ButtonIcon
                    icon={'Plus'}
                    isFree
                    label={'Add resource'}
                    size={'xsm'}
                    onClick={() => resourcesFieldArray.append(BASE_RESOURCE)}
                  />
                </div>
              </div>
              {resourcesFieldArray.fields.map((field, index) => (
                <DragItem
                  key={field.uniqueId}
                  id={field.uniqueId}
                  index={index}
                  moveItem={(draggedId, hoverIndex) => {
                    const draggedIndex = resourcesFieldArray.fields.findIndex((field) => draggedId === field.uniqueId);

                    resourcesFieldArray.move(draggedIndex, hoverIndex);
                  }}
                  findItem={(id) => {
                    const index = resourcesFieldArray.fields.findIndex((field) => id === field.uniqueId);

                    return {
                      id: id,
                      index: index,
                    };
                  }}
                  removeDisabled={false}
                  handleRemove={() => resourcesFieldArray.remove(index)}
                  type={'resources'}
                >
                  {field.type === 'file' ? (
                    <LinkStyled onClick={() => getDownloadMeetingFile(field.id!, field.name!)}>{field.name}</LinkStyled>
                  ) : (
                    <>
                      <div className="mb-1">
                        <Input
                          {...register(`resources.${index}.title` as const)}
                          id={`resource-title-${field.uniqueId}`}
                          placeholder={'Title'}
                          error={errors.resources?.[index]?.title?.message}
                        />
                      </div>
                      <div>
                        <Input
                          {...register(`resources.${index}.url` as const)}
                          id={`resource-url-${field.uniqueId}`}
                          placeholder={'URL'}
                          error={errors.resources?.[index]?.url?.message}
                        />
                      </div>
                    </>
                  )}
                </DragItem>
              ))}
            </div>
          </DndProvider>
          <DragAndDropFile
            files={watch('files')}
            setFile={(files) => {
              setValue('files', files as File[]);
            }}
            error={errors.files?.message}
            text={'Click or drag and drop to add a file'}
          />
        </ModalComponents.Body>
        <ModalComponents.Footer>
          <ModalComponents.CancelButton onClick={() => toggle(false)} />
          <ModalComponents.ConfirmButton
            label={isSubmitting || postMeetingUpdateAgendaMutation.isLoading ? 'Loading...' : 'Confirm'}
            type={'submit'}
            disabled={isSubmitting || postMeetingUpdateAgendaMutation.isLoading}
          />
        </ModalComponents.Footer>
      </form>
    </Modal>
  );
}

interface Props extends Pick<ModalProps, 'toggle'> {
  meetingAgendaToEdit: MeetingAgendaToEdit;
  meetingPathway: MeetingPathway | null;
}

export interface MeetingAgendaToEdit {
  id: string;
  prerequisites: {
    id: string | null;
    title: string;
  }[];
  agendaItems: {
    id: string | null;
    title: string;
  }[];
  resources: {
    id: string | null;
    title: string;
    url: string;
  }[];
}

interface FormValues {
  prerequisites: {
    id?: string | null;
    title: string;
  }[];
  agendaItems: {
    id?: string | null;
    title: string;
  }[];
  resources: {
    id?: string | null;
    title: string;
    url?: string;
    name?: string;
    type?: 'file' | 'url';
  }[];
  files: File[];
  meetingPurpose?: string;
  type?: string;
  id?: string;
}
