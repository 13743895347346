import { PopupButton } from '@typeform/embed-react';
import { useQuery } from "react-query";
import { ME } from "../service/queryKeys";
import { getMe } from "../service/api";
import Button from '../global/button/button';

const PopupButtons = ({ formId }: { formId: string }) => {
  const { data: me } = useQuery(ME, getMe, {
    staleTime: Infinity,
    select: (data) => data.data.data.me,
  });

  return (
    <PopupButton
      id={formId}
      hidden={{
        user_id : me.id,
        name: me.first_name,
        email: me.email,
        role: me.role
      }}
      fullScreen={false}
      width={600}
      height={400}
      style={{ fontSize: 20 }}
      className="my-button"
    >
      <div className="flex justify-center items-center h-full w-full">
        <Button
          id="typeform_product_feedback_submit"
          type="submit"
          className="w-auto mt-5"
        >
          We Value Your Feedback
        </Button>
      </div>
    </PopupButton>
  );
};

export default PopupButtons;
