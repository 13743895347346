import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import { UserType } from '../userManagementPage';
import Input from '../../../form/input/input';
import Alert from '../../../global/alert/alert';
import {
  ROLE_ASSISTANT_GT_COORDINATOR,
  ROLE_MAT_COORDINATOR,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_SYS_ADMIN,
  ROLE_TEACHER,
  ROLE_TEACHER_FREE,
  SUBSCRIPTION_TYPE_BULK,
  SUBSCRIPTION_TYPE_PAID,
} from '../../../resources/roles';
import Selector from '../../../form/select/selector';
import { COUNTRIES } from '../../../resources/countries';
import { TIME_ZONES } from '../../../resources/timezones';
import DefaultTable from '../../../global/table/defaultTable';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getSubjects } from '../../../service/api';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  emailValidationDebounced,
  FIRST_NAME_VALIDATION,
  LAST_NAME_VALIDATION,
  PASSWORD_VALIDATION,
} from '../../../resources/schemas';
import RadioGroup from '../../../form/choice/radioGroup';
import { SelectOptionType } from '../../../service/types';
import MessageAtMenuList from '../../../form/select/components/messageAtMenuList';
import Modal from '../../../global/messages/modal/modal.components';
import { YEARS } from '../../../resources/years';
import Label from '../../../form/common/label';
import RadioGroupOnBlock from '../../../form/choice/radioGroupOnBlock';
import {
  ASSOCIATE_COLLEGES,
  SCHOOL_FROM_COORDINATOR,
  SUBJECTS,
  USER_MANAGEMENT_LIST
} from '../../../service/queryKeys';
import { DefaultOptionType } from '../../../resources/types';
import { ROLE_OPTIONS } from '../../auth/Registration/sections/step4/TeacherForm';
import { CATEGORY_1_OPTIONS, CATEGORY_2_OPTIONS, CATEGORY_3_OPTIONS } from '../../auth/Registration/Step2';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateOrUpdateUserType, getSchoolFromCoordinator, postUser, putUserInfo } from '../../../service/api/adminApi';
import { debounce } from 'lodash';
import { REGION_LABELS, REGIONS } from '../../../resources/regions';
import { ASSOCIATED_COLLEGES, ASSOCIATED_COLLEGES_LABELS } from '../../../resources/associateColleges';
import { getAssociateColleges } from "../../../service/api/schoolApi";



const TAG_GREAT_TEACHING_LEAD = 'Great Teaching lead';
const TAG_MENTOR = 'Mentor';
const TAG_INDUCTION_LEAD = 'Induction Lead';
const TAG_ASSOCIATE_COLLEGE = 'Associate College';
const TAG_REGIONAL_LEAD = 'Regional Lead';
const TAG_NIOT = 'NIoT';
const TAG_ECT = 'Early career teacher';

// Just used to differentiate individual teacher from linked teacher in the select (both are ROLE_TEACHER in the db)
const ROLE_LINKED_TEACHER = 'ROLE_LINKED_TEACHER';

const ROLES = [
  {
    label: 'Coordinator',
    value: ROLE_SCHOOL_COORDINATOR,
  },
  {
    label: 'Assistant coordinator',
    value: ROLE_ASSISTANT_GT_COORDINATOR,
  },
  {
    label: 'Teacher',
    value: ROLE_LINKED_TEACHER,
  },
  {
    label: 'Teacher (individual)',
    value: ROLE_TEACHER,
  },
];

const TAGS = [
  TAG_GREAT_TEACHING_LEAD,
  TAG_MENTOR,
  TAG_INDUCTION_LEAD,
  TAG_ASSOCIATE_COLLEGE,
  TAG_REGIONAL_LEAD,
  TAG_NIOT,
  TAG_ECT,
];

export const CREATE_ADMIN = 'CREATE_ADMIN';
export const CREATE_USER = 'CREATE_USER';

const getTagOptions = (user?: UserType) => {
  if (!user) {
    return null;
  }

  const tags = [];

  if (user.mentor) {
    tags.push(TAG_MENTOR);
  }

  if (user.great_teaching_lead) {
    tags.push(TAG_GREAT_TEACHING_LEAD);
  }

  if (user.is_associate_college) {
    tags.push(TAG_ASSOCIATE_COLLEGE);
  }

  if (user.is_regional_lead) {
    tags.push(TAG_REGIONAL_LEAD);
  }

  if (user.is_induction_lead) {
    tags.push(TAG_INDUCTION_LEAD);
  }

  if (user.is_niot) {
    tags.push(TAG_NIOT);
  }

  if (user.is_early_career_teacher) {
    tags.push(TAG_ECT);
  }

  return tags.map((tag) => ({ label: tag, value: tag }));
};

const UserFormModal = ({
  userToEdit,
  toggle,
  createMode,
}: ModalToggleProps & {
  userToEdit?: UserType;
  createMode?: typeof CREATE_ADMIN | typeof CREATE_USER;
}) => {
  const queryClient = useQueryClient();

  const createUser = useMutation(postUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      toast.success('User successfully created');
      toggle(false);
    },
    onError: () => {
      toast.error('An error has occurred');
    },
  });

  const { data: subjects } = useQuery(SUBJECTS, getSubjects, {
    staleTime: Infinity,
    select: (data) => data.data.data,
    enabled: userToEdit?.role === ROLE_TEACHER || userToEdit?.role === ROLE_TEACHER_FREE,
  });

  const { data: associateColleges } = useQuery(ASSOCIATE_COLLEGES, getAssociateColleges, {
    staleTime: Infinity,
    select: (data) => data.data.data,
  });

  const updateUser = useMutation(putUserInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      toast.success('User successfully updated');
      toggle(false);
    },
    onError: (error) => {
      toast.error(`An error has occurred ${(error as any)?.response.data.detail}`);
    },
  });

  const getSchema = (): any => {
    const SCHEMA = Yup.object().shape({
      role: Yup.string()
        .nullable()
        .test('is-role-required', 'role is a required field', (value) => {
          if (!userToEdit || userToEdit?.role === ROLE_TEACHER || userToEdit?.role === ROLE_SYS_ADMIN) {
            return true;
          }

          return !!value;
        }),
      first_name: FIRST_NAME_VALIDATION,
      last_name: LAST_NAME_VALIDATION,
      email: Yup.string()
        .trim()
        .email()
        .max(180)
        .required()
        .test('is-email-taken', 'Email already taken', (value) =>
          userToEdit?.email?.toLowerCase() === value?.toLowerCase()
            ? true
            : new Promise((resolve) => emailValidationDebounced(value, resolve))
        ),
      organisation: Yup.string()
        .trim()
        .max(150)
        .nullable()
        .test('is-organisation-required', 'School/Organisation is a required field', (value) => {
          if (!userToEdit || userToEdit?.role === ROLE_TEACHER || userToEdit?.role === ROLE_SYS_ADMIN) {
            return true;
          }

          return value !== '' && value != null;
        }),
      country: Yup.string()
        .nullable()
        .test(
          'is-country-required',
          'Country is a required field',

          (value) => {
            if (!userToEdit || userToEdit?.role === ROLE_TEACHER || userToEdit?.role === ROLE_SYS_ADMIN) {
              return true;
            }

            return value !== '' && value != null;
          }
        ),
      timezone: Yup.string()
        .nullable()
        .test('is-timezone-required', 'Timezone is a required field', (value) => {
          if (!userToEdit || userToEdit?.role === ROLE_TEACHER || userToEdit?.role === ROLE_SYS_ADMIN) {
            return true;
          }

          return value !== '' && value != null;
        }),
      associated_coordinator: Yup.string().when('role', (role: string) => {
        if (role === ROLE_ASSISTANT_GT_COORDINATOR || role === ROLE_LINKED_TEACHER) {
          return Yup.string().trim().email().max(180).required();
        }

        return Yup.string().nullable();
      }),
      urn: Yup.string().when('role', (role: string) => {
        if (role === ROLE_SCHOOL_COORDINATOR || role === ROLE_TEACHER) {
          return Yup.string().trim().required();
        }

        return Yup.string().nullable();
      }),
      trn: Yup.number().required(),
      tags: Yup.array(),
    });

    if (createMode === CREATE_ADMIN) {
      return SCHEMA.shape({
        password: PASSWORD_VALIDATION,
      });
    }

    switch (userToEdit?.role) {
      case ROLE_MAT_COORDINATOR:
        return SCHEMA.shape({
          schools: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().trim().max(150).required(),
              slots: Yup.number().min(0).required(),
              coordinator_name: Yup.string().trim().max(32).required(),
              coordinator_email: Yup.string().email().max(180).required(),
            })
          ),
        });
      case ROLE_SCHOOL_COORDINATOR:
        return SCHEMA.shape({
          slots: Yup.number().min(0),
          age_range: Yup.array().min(1).required(),
          category_1: Yup.string().trim().required(),
          category_2: Yup.string().trim().required(),
          category_3: Yup.string().trim().required(),
        });
      case ROLE_TEACHER:
      case ROLE_TEACHER_FREE:
        return SCHEMA.shape({
          role_description: Yup.string().trim().required(),
          role_type: Yup.string().trim().required(),
          role_other_type: Yup.string().when('role_type', { is: 'Other', then: Yup.string().trim().required() }),
          subjects: Yup.array().when('role_type', (roleType: string) => {
            if (userToEdit.role !== ROLE_TEACHER_FREE || roleType !== 'Other') {
              return Yup.array().min(1);
            }

            return Yup.array();
          }),
          mentoring: Yup.boolean()
            .nullable()
            .when('role_type', (roleType: string) => {
              if (userToEdit.role !== ROLE_TEACHER_FREE || roleType !== 'Other') {
                return Yup.boolean().required();
              }

              return Yup.boolean().nullable();
            }),
          experience: Yup.number()
            .nullable()
            .when('role_type', (roleType: string) => {
              if (userToEdit.role !== ROLE_TEACHER_FREE || roleType !== 'Other') {
                return Yup.number().min(0).required();
              }

              return Yup.number().nullable();
            }),
          year: Yup.number().when('role_type', (roleType: string) => {
            if (userToEdit.role !== ROLE_TEACHER_FREE || roleType !== 'Other') {
              return Yup.number().required();
            }

            return Yup.number();
          }),
          qualifications: Yup.string()
            .trim()
            .when('role_type', (roleType: string) => {
              if (userToEdit.role !== ROLE_TEACHER_FREE || roleType !== 'Other') {
                return Yup.string().required();
              }

              return Yup.string();
            }),
        });
    }

    return SCHEMA;
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(getSchema()),
    defaultValues: {
      password: undefined,
      ...userToEdit,
      role:
        userToEdit?.role === ROLE_TEACHER && userToEdit.subscription_type === SUBSCRIPTION_TYPE_BULK
          ? ROLE_LINKED_TEACHER
          : userToEdit?.role,
      tags: getTagOptions(userToEdit),
      regions: userToEdit?.regions
        ? userToEdit.regions.map((region) => ({
            label: region.name,
            value: region.id,
          }))
        : null,
      associate_colleges:
        (userToEdit?.role === ROLE_SCHOOL_COORDINATOR
          ? userToEdit?.school_associate_colleges
          : userToEdit?.associate_colleges
        )?.map((associateCollege) => ({
          label: associateCollege.name,
          value: associateCollege.id,
        })) ?? null,
      region: userToEdit?.region
        ? {
            label: userToEdit.region.name,
            value: userToEdit.region.id,
          }
        : null,
      role_type:
        userToEdit?.role === ROLE_TEACHER || userToEdit?.role === ROLE_TEACHER_FREE
          ? ROLE_OPTIONS.map((option) => option.value)
              .filter((option: string) => option !== 'Other')
              .find((option) => option === userToEdit.role_type) ?? 'Other'
          : undefined,
      role_other_type:
        userToEdit?.role === ROLE_TEACHER || userToEdit?.role === ROLE_TEACHER_FREE
          ? ROLE_OPTIONS.map((option) => option.value)
              .filter((option: string) => option !== 'Other')
              .find((option) => option === userToEdit.role_type) == null
            ? userToEdit.role_type
            : ''
          : undefined,
    },
  });

  const associatedCoordinatorEmail = watch('associated_coordinator');
  const selectedRole = watch('role');
  const schoolQuery = useQuery(
    [SCHOOL_FROM_COORDINATOR, associatedCoordinatorEmail],
    () => getSchoolFromCoordinator(associatedCoordinatorEmail!),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      enabled:
        !!associatedCoordinatorEmail &&
        (selectedRole === ROLE_ASSISTANT_GT_COORDINATOR || selectedRole === ROLE_LINKED_TEACHER),
    }
  );

  const updateAssociatedCoordinatorEmail = debounce((e) => register('associated_coordinator').onChange(e), 500);

  return (
    <Modal>
      <form
        onSubmit={handleSubmit((values) => {
          const data: CreateOrUpdateUserType = {
            role:
              createMode === CREATE_ADMIN
                ? ROLE_SYS_ADMIN
                : values.role === ROLE_LINKED_TEACHER
                ? ROLE_TEACHER
                : values.role!,
            first_name: values.first_name!,
            last_name: values.last_name!,
            email: values.email!,

            country: values.country,
            timezone: values.timezone,
            organisation: values.organisation,
            role_description: values.role_description,
            role_type: values.role_type === 'Other' ? values.role_other_type : values.role_type,
            subjects: values.subjects,
            mentoring: values.mentoring,
            experience: values.experience,
            year: values.year?.toString(),
            qualifications: values.qualifications,

            category_1: values.category_1,
            category_2: values.category_2,
            category_3: values.category_3,
            age_range: values.age_range,

            schools: values.schools,
            urn: values.urn?.toString(),
            associated_coordinator: values.associated_coordinator,
            trn: values.trn?.toString(),

            associate_colleges: values.associate_colleges?.map((associateCollege) => associateCollege.value),
            regions: values.regions?.map((region) => region.value),
            region: values.region?.value,
            school_associate_colleges: values.associate_colleges?.map((associateCollege) => associateCollege.value),
          };

          if (values.slots) {
            if (createMode === CREATE_USER) {
              data.subscription = {
                teachers: values.slots,
              };
            } else if (userToEdit) {
              data.slots = values.slots;
            }
          }

          for (const tag of values?.tags ?? []) {
            if (tag.value === TAG_GREAT_TEACHING_LEAD) {
              data.is_great_teaching_lead = true;
            } else if (
              (values.role === ROLE_TEACHER || values.role === ROLE_SCHOOL_COORDINATOR) &&
              tag.value === TAG_MENTOR
            ) {
              data.is_mentor = true;
            } else if (values.role === ROLE_SCHOOL_COORDINATOR && tag.value === TAG_INDUCTION_LEAD) {
              data.is_induction_lead = true;
            } else if (tag.value === TAG_ASSOCIATE_COLLEGE) {
              data.is_associate_college = true;
            } else if (values.role === ROLE_TEACHER && tag.value === TAG_REGIONAL_LEAD) {
              data.is_regional_lead = true;
            } else if (values.role === ROLE_TEACHER && tag.value === TAG_NIOT) {
              data.is_niot = true;
            } else if (values.role === ROLE_TEACHER && tag.value === TAG_ECT) {
              data.is_early_career_teacher = true;
            }
          }

          if (!userToEdit) {
            createUser.mutate(data);
            return;
          }

          updateUser.mutate({ params: { id: userToEdit.id }, body: data });
        })}
      >
        <Modal.Body>
          {userToEdit && !userToEdit.active && (
            <Alert className={'field-mb'}>You are editing a deactivated user.</Alert>
          )}
          {createMode === CREATE_ADMIN && (
            <p className={'field-mb'}>
              Fill the form below in order to add a new user with <b>system administrator permissions</b>.
            </p>
          )}
          {createMode === CREATE_USER && (
            <div className={'field-mb'}>
              <Controller
                control={control}
                render={({ field }) => (
                  <Selector
                    {...field}
                    id={'roles'}
                    label={'Role'}
                    options={ROLES}
                    onChange={(value: DefaultOptionType) => {
                      setValue('tags', []);
                      setValue('associated_coordinator', undefined);
                      setValue('urn', undefined);
                      setValue('region', null);
                      setValue('associate_colleges', null);

                      field.onChange(value.value);
                    }}
                    value={ROLES.find((role) => role.value === selectedRole)}
                    placeholder={'Please select a role'}
                    required
                    isSearchable={false}
                    isClearable={false}
                  />
                )}
                name={'role'}
              />
            </div>
          )}
          <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
            <div className={'field-mb'}>
              <Input
                {...register('first_name')}
                id="first_name"
                label={'First Name'}
                placeholder={'Please enter a first name'}
                required
              />
            </div>
            <div className={'field-mb'}>
              <Input
                {...register('last_name')}
                id="last_name"
                label="Last Name"
                placeholder={'Please enter a last name'}
                required
              />
            </div>
          </div>
          <div className={'field-mb'}>
            <Input
              {...register('email')}
              id="email"
              type={'email'}
              label="Email"
              placeholder={'Please enter an email'}
              required
              error={errors.email?.message}
            />
          </div>
          {createMode === CREATE_ADMIN && (
            <div className={'field-mb'}>
              <Input
                {...register('password')}
                id="password"
                type={'password'}
                label="Password"
                placeholder={'Please enter a password'}
                required
                error={errors.password?.message}
              />
            </div>
          )}
          {(selectedRole === ROLE_ASSISTANT_GT_COORDINATOR || selectedRole === ROLE_LINKED_TEACHER) && (
            <>
              <div className={'field-mb'}>
                <Input
                  {...register('associated_coordinator')}
                  id="associated_coordinator"
                  type={'email'}
                  label="Associated coordinator"
                  placeholder={'Please enter an email'}
                  required
                  error={errors.associated_coordinator?.message}
                  onChange={(e) => updateAssociatedCoordinatorEmail(e)}
                />
              </div>
              <div className={'field-mb'}>
                <Input value={schoolQuery.data?.name ?? ''} id="organisation" label={'School'} disabled required />
              </div>
            </>
          )}
          {/* Common Users Fields */}
          {createMode !== CREATE_ADMIN && ROLE_SYS_ADMIN !== userToEdit?.role && (
            <React.Fragment>
              <div className={'field-mb'}>
                {(ROLE_MAT_COORDINATOR === userToEdit?.role ||
                  (ROLE_SCHOOL_COORDINATOR === userToEdit?.role && !userToEdit?.token) ||
                  ROLE_TEACHER_FREE === userToEdit?.role ||
                  selectedRole === ROLE_SCHOOL_COORDINATOR ||
                  selectedRole === ROLE_TEACHER) && (
                  <Input
                    {...register('organisation')}
                    id="organisation"
                    label={ROLE_MAT_COORDINATOR === userToEdit?.role ? 'Organisation' : 'School'}
                    placeholder={
                      ROLE_MAT_COORDINATOR === userToEdit?.role
                        ? 'Please enter an organisation name'
                        : 'Please enter a school name'
                    }
                    required
                  />
                )}
              </div>
              {userToEdit && userToEdit.role !== ROLE_TEACHER && (
                <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
                  <div className={'field-mb'}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Selector
                          {...field}
                          id={'country'}
                          label={'Country'}
                          placeholder={'Please select a country'}
                          required
                          options={COUNTRIES}
                          value={COUNTRIES.find((country) => country.value === field.value)}
                          onChange={(option: DefaultOptionType) => field.onChange(option.value)}
                          isClearable={false}
                        />
                      )}
                      name={'country'}
                    />
                  </div>
                  <div className={'field-mb'}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Selector
                          {...field}
                          id={'timezone'}
                          label={'Time Zone'}
                          placeholder={'Please select a time zone'}
                          required
                          options={TIME_ZONES}
                          value={TIME_ZONES.find((timezone) => timezone.value === field.value)}
                          onChange={(option: DefaultOptionType) => field.onChange(option.value)}
                          isClearable={false}
                        />
                      )}
                      name={'timezone'}
                    />
                  </div>
                </div>
              )}
              {userToEdit && ROLE_MAT_COORDINATOR === userToEdit.role && (
                <div className={'field-mb'}>
                  <h2>Schools</h2>
                  <p className={'mb-3'}>
                    These are the schools managed by this <b>Group admin user</b>.
                  </p>
                  <DefaultTable
                    id={'mat_schools'}
                    itemCounter={{ single: 'school', plural: 'schools' }}
                    noDataMessage={'This user does not manage any schools.'}
                    columns={[
                      { id: 'school_name', label: 'School name' },
                      { id: 'max_number', label: 'Max. number of teachers' },
                      { id: 'coordinator_name', label: 'Coordinator name' },
                      { id: 'coordinator_email', label: 'Coordinator email' },
                    ]}
                    rows={(watch('schools') ?? []).map((school, index) => {
                      return {
                        id: school.id,
                        cells: [
                          {
                            id: 'school_name',
                            content: (
                              <Input
                                {...register(`schools.${index}.name`)}
                                id={'school_name'}
                                label={'School Name'}
                                hideLabel
                                placeholder={'Enter a school name'}
                              />
                            ),
                          },
                          {
                            id: 'max_number',
                            content: (
                              <Input
                                {...register(`schools.${index}.slots`)}
                                type={'number'}
                                id={'teachers_num'}
                                label={'Number of teachers that should access the platform'}
                                hideLabel
                                min={0}
                                placeholder={'Please enter the number of teachers'}
                              />
                            ),
                          },
                          {
                            id: 'coordinator_name',
                            content: (
                              <Input
                                {...register(`schools.${index}.coordinator_name`)}
                                id={'coordinator_name'}
                                label={'Coordinator Name'}
                                hideLabel
                                placeholder={'Enter a coordinator name'}
                              />
                            ),
                          },
                          {
                            id: 'coordinator_email',
                            content: (
                              <Input
                                {...register(`schools.${index}.coordinator_email`)}
                                type={'email'}
                                id={'coordinator_email'}
                                label={'Coordinator Email'}
                                hideLabel
                                placeholder={'Enter a coordinator email'}
                              />
                            ),
                          },
                        ],
                      };
                    })}
                  />
                </div>
              )}
            </React.Fragment>
          )}
          {[ROLE_SCHOOL_COORDINATOR, ROLE_TEACHER].includes(selectedRole!) && (
            <div className={'mb-8'}>
              <Input
                {...register('urn')}
                id={'urn'}
                name="urn"
                label={'URN'}
                placeholder={"Please enter the school's urn"}
                required
              />
            </div>
          )}
          {ROLE_SCHOOL_COORDINATOR === selectedRole && (
            <>
              <div className={'field-mb'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Selector
                      {...field}
                      id={'region'}
                      label={'Region'}
                      placeholder={'Please select a region'}
                      options={Array.from({ length: REGIONS }, (_, i) => ({
                        value: i + 1,
                        label: REGION_LABELS[i + 1] ?? i + 1,
                      }))}
                    />
                  )}
                  name={'region'}
                />
              </div>
              <div className={'field-mb'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Selector
                      {...field}
                      id={'associated_colleges'}
                      label={'Associated colleges'}
                      placeholder={'Please select your associated colleges'}
                      options={Array.from({ length: associateColleges?.length || 0 }, (_, i) => ({
                        value: i + 1,
                        label: associateColleges[i]?.name ?? `College ${i + 1}`,  // Correct indexing
                      }))}

                      isMulti
                    />
                  )}
                  name={'associate_colleges'}
                />
              </div>
            </>
          )}
          {selectedRole === ROLE_SCHOOL_COORDINATOR &&
            (userToEdit?.subscription_type === SUBSCRIPTION_TYPE_PAID || createMode === CREATE_USER) && (
              <div className={'mb-8'}>
                <Input
                  {...register('slots')}
                  id={'teachers_num'}
                  name="slots"
                  label={'Number of teachers that should access the platform'}
                  placeholder={'Please enter the number of teachers'}
                  type={'number'}
                  min={0}
                  required
                />
              </div>
            )}

          {userToEdit && userToEdit.role === ROLE_SCHOOL_COORDINATOR && (
            <>
              <div className={'mb-8'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Selector
                      {...field}
                      isMulti
                      isCreatableSelect
                      id={'age_range'}
                      label={'School/college age range'}
                      placeholder={'Please select one or more age ranges'}
                      options={[
                        { value: '4-11', label: '4-11' },
                        { value: '11-16', label: '11-16' },
                        { value: '11-18', label: '11-18' },
                      ]}
                      value={(field.value ?? []).map((value) => ({
                        label: value,
                        value,
                      }))}
                      onChange={(options: DefaultOptionType[]) =>
                        field.onChange(
                          options == null ? [] : options.map((option: SelectOptionType) => option.value as string)
                        )
                      }
                      required
                      isClearable={false}
                      customComponents={{
                        MenuList: function MenuList(menuListProps) {
                          return (
                            <MessageAtMenuList
                              message={"Don't see your age range? Add it by populating the field above."}
                              {...menuListProps}
                            />
                          );
                        },
                      }}
                    />
                  )}
                  name={'age_range'}
                />
              </div>
              <div className={'mb-8'}>
                <Label label={'Is the school'} id={'is_the_school'} required />
                <div className={'flex items-center gap-4 sm-field-mb'}>
                  <div>1.</div>
                  <div className={'flex-grow'}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <RadioGroupOnBlock
                          id={'category_1'}
                          label={'Founding'}
                          hideLabel
                          options={CATEGORY_1_OPTIONS}
                          optionChecked={field.value}
                          handleChange={(value) => field.onChange(value)}
                        />
                      )}
                      name={'category_1'}
                    />
                  </div>
                </div>
                <div className={'flex items-center gap-4 sm-field-mb'}>
                  <div>2.</div>
                  <div className={'flex-grow'}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <RadioGroupOnBlock
                          id={'category_2'}
                          label={'Scope'}
                          hideLabel
                          options={CATEGORY_2_OPTIONS}
                          optionChecked={field.value}
                          handleChange={(value) => field.onChange(value)}
                        />
                      )}
                      name={'category_2'}
                    />
                  </div>
                </div>
                <div className={'flex items-center gap-4 sm-field-mb'}>
                  <div>3.</div>
                  <div className={'flex-grow'}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <RadioGroupOnBlock
                          id={'category_3'}
                          label={'Selection'}
                          hideLabel
                          options={CATEGORY_3_OPTIONS}
                          optionChecked={field.value}
                          handleChange={(value) => field.onChange(value)}
                        />
                      )}
                      name={'category_3'}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {userToEdit && (ROLE_TEACHER === userToEdit?.role || ROLE_TEACHER_FREE === userToEdit?.role) && (
            <div className={'mb-8'}>
              <Input
                {...register('role_description')}
                id={'role_description'}
                label={'Your role'}
                placeholder={'Please describe your role'}
                required
              />
            </div>
          )}
          {createMode !== CREATE_ADMIN &&
            ![ROLE_SYS_ADMIN, ROLE_MAT_COORDINATOR, ROLE_TEACHER_FREE].includes(userToEdit?.role ?? '') && (
              <>
                <div className={'field-mb'}>
                  <Input
                    {...register('trn')}
                    id={'trn'}
                    label={'TRN'}
                    placeholder={'Please describe your teacher reference number'}
                    required
                  />
                </div>
                <div className={'field-mb'}>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Selector
                        {...field}
                        isMulti
                        id={'tags'}
                        label={'Tags'}
                        placeholder={'Please select your tags'}
                        options={TAGS.filter((tag) => {
                          if (tag === TAG_INDUCTION_LEAD) {
                            return selectedRole === ROLE_SCHOOL_COORDINATOR;
                          } else if (tag === TAG_MENTOR) {
                            return selectedRole === ROLE_TEACHER || selectedRole === ROLE_SCHOOL_COORDINATOR;
                          } else if (tag === TAG_NIOT) {
                            return selectedRole === ROLE_TEACHER;
                          } else if (tag === TAG_REGIONAL_LEAD) {
                            return selectedRole === ROLE_TEACHER || selectedRole === ROLE_LINKED_TEACHER;
                          } else if (tag === TAG_ASSOCIATE_COLLEGE) {
                            return selectedRole === ROLE_TEACHER || selectedRole === ROLE_LINKED_TEACHER;
                          } else if (tag === TAG_ECT) {
                            return selectedRole === ROLE_TEACHER || selectedRole === ROLE_LINKED_TEACHER;
                          }

                          return true;
                        }).map((tag) => ({
                          value: tag,
                          label: tag,
                        }))}
                        isClearable={false}
                        onChange={(options: DefaultOptionType[]) => {
                          if (!options.some((option) => option.value === TAG_REGIONAL_LEAD)) {
                            setValue('regions', null);
                          }

                          if (!options.some((option) => option.value === TAG_ASSOCIATE_COLLEGE)) {
                            setValue('associate_colleges', null);
                          }

                          field.onChange(options);
                        }}
                      />
                    )}
                    name={'tags'}
                  />
                </div>
                {watch('tags')?.some((tag) => tag.value === TAG_REGIONAL_LEAD) && (
                  <div className={'field-mb'}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Selector
                          {...field}
                          id={'regions'}
                          label={'Regions'}
                          placeholder={'Please select your regions'}
                          required
                          options={Array.from({ length: REGIONS }, (_, i) => ({
                            value: i + 1,
                            label: REGION_LABELS[i + 1] ?? i + 1,
                          }))}
                          isClearable={false}
                          isMulti
                        />
                      )}
                      name={'regions'}
                    />
                  </div>
                )}
                {watch('tags')?.some((tag) => tag.value === TAG_ASSOCIATE_COLLEGE) && (
                  <div className={'field-mb'}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Selector
                          {...field}
                          id={'associated_colleges'}
                          label={'Associated colleges'}
                          placeholder={'Please select your associated colleges'}
                          required
                          options={Array.from({ length: associateColleges?.length || 0 }, (_, i) => ({
                            value: i + 1,
                            label: associateColleges[i]?.name ?? `College ${i + 1}`,  // Correct indexing
                          }))}

                          isClearable={false}
                          isMulti
                        />
                      )}
                      name={'associate_colleges'}
                    />
                  </div>
                )}
              </>
            )}
          {userToEdit && (ROLE_TEACHER === userToEdit?.role || ROLE_TEACHER_FREE === userToEdit?.role) && (
            <>
              <div className={'mb-8'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      id={'role_type'}
                      label={'Which of these best describes your role type?'}
                      options={ROLE_OPTIONS}
                      isGroupRequired
                      optionChecked={field.value}
                      onChange={(e) => {
                        if ('Other' !== e.target.value) {
                          setValue('role_other_type', '');
                          if (userToEdit?.role === ROLE_TEACHER_FREE) {
                            setValue('subjects', []);
                            setValue('mentoring', null);
                            setValue('experience', null);
                            setValue('year', null);
                            setValue('qualifications', '');
                          }
                        }

                        field.onChange(e);
                      }}
                    />
                  )}
                  name={'role_type'}
                />
                {watch('role_type') === 'Other' && (
                  <div className={'animate-fade-in mt-5'}>
                    <Input
                      {...register('role_other_type')}
                      id={'role_other_type'}
                      label={'Other role type'}
                      placeholder={'Please add other option'}
                      hideLabel
                      autoFocus
                    />
                  </div>
                )}
              </div>
              {(userToEdit!.role !== ROLE_TEACHER_FREE || watch('role_type') !== 'Other') && (
                <>
                  <div className={'mb-8'}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Selector
                          {...field}
                          id={'subjects'}
                          label={'Please select the subjects you teach. If you teach primary students select “Primary”'}
                          placeholder={'Please select the subjects you teach'}
                          required
                          options={subjects}
                          isMulti
                          value={(field.value ?? []).map((subject) => {
                            return { label: subject, value: subject };
                          })}
                          onChange={(options: DefaultOptionType<string>[]) => {
                            field.onChange(options == null ? [] : options.map((option) => option.value));
                          }}
                          isClearable={false}
                          isCreatableSelect
                          customComponents={{
                            MenuList: function MenuList(menuListProps) {
                              return (
                                <MessageAtMenuList
                                  message={"Don't see your subject? Add it by populating the field above."}
                                  {...menuListProps}
                                />
                              );
                            },
                          }}
                        />
                      )}
                      name={'subjects'}
                    />
                  </div>
                  <div className={'mb-8'}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          id={'mentoring'}
                          label={'Does your role currently include formally mentoring or supporting other teachers?'}
                          options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                          ]}
                          isGroupRequired
                          optionChecked={field.value}
                          onChange={(e) => field.onChange(e.target.value === 'true')}
                        />
                      )}
                      name={'mentoring'}
                    />
                  </div>
                  <div className={'mb-8'}>
                    <Input
                      {...register('experience')}
                      id={'experience'}
                      type={'number'}
                      label={"How many complete years' experience as a teacher do you have?"}
                      placeholder={'For example, 7'}
                      required
                      min={0}
                    />
                  </div>
                  <div className={'mb-8'}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Selector
                          {...field}
                          id={'year'}
                          label={'When did you qualify as a teacher?'}
                          placeholder={'Please select a year'}
                          required
                          options={YEARS}
                          value={field.value != null ? { label: field.value, value: field.value } : null}
                          onChange={(option: SelectOptionType) =>
                            field.onChange(option != null ? (option.value as number) : undefined)
                          }
                          isClearable={false}
                        />
                      )}
                      name={'year'}
                    />
                  </div>
                  <div className={'mb-8'}>
                    <Input
                      {...register('qualifications')}
                      id={'qualifications'}
                      label={'What teaching or related qualifications do you have?'}
                      placeholder={'Please enter your qualification information'}
                      required
                    />
                  </div>
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)} />
          <Modal.ConfirmButton type="submit" label={userToEdit ? 'Edit user' : 'Add user'} />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default UserFormModal;
