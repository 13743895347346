import React from 'react';
import { Me } from '../../../@types/Entity/Me';

export interface onboardingStepInterface {
  id:
    | 'welcome'
    | 'academic_year'
    | 'terms'
    | 'implementation_intention'
    | 'registration'
    | 'complete'
    | 'welcomeTeacher'
    | 'completeTeacher';
  title: string;
  subtitle: string;
  menuTitle: string;
  index: number;
  finished: boolean;
}

export const onboardingStepsCoordinator = (user: Me | undefined): onboardingStepInterface[] => {
  return [
    {
      title: 'Welcome to the',
      subtitle: '',
      menuTitle: 'Welcome!',
      index: 0,
      finished: false,
      id: 'welcome',
    },
    {
      title: 'Please confirm the academic year at ' + user?.organisation,
      subtitle: 'Do these dates look about right? You can edit them now or later on in your School Planner',
      menuTitle: 'Academic Year',
      index: 1,
      finished: false,
      id: 'academic_year',
    },
    {
      title: 'And now your term dates',
      subtitle: 'Adding these will give you a clearer picture of how your teachers progress throughout the year',
      menuTitle: 'Term dates',
      index: 2,
      finished: false,
      id: 'terms',
    },
    {
      title: 'How will you start?',
      subtitle: `Which of the following best describes how you're starting with the GTT at ${user?.organisation}?`,
      menuTitle: 'Implementation goal',
      index: 3,
      finished: false,
      id: 'implementation_intention',
    },
    {
      title: 'Invite your colleagues',
      subtitle: "When you're ready to invite your colleagues to the toolkit, copy and send them the registration link",
      menuTitle: 'Invite your colleagues',
      index: 4,
      finished: false,
      id: 'registration',
    },
    {
      title: 'Let the learning begin!',
      subtitle: '',
      menuTitle: 'All done!',
      index: 5,
      finished: false,
      id: 'complete',
    },
  ];
};

export const onboardingStepsTeacher = (user: Me | undefined): onboardingStepInterface[] => {
  return [
    {
      title: `${user?.first_name}, welcome to the`,
      subtitle: '',
      menuTitle: 'Welcome!',
      index: 0,
      finished: false,
      id: 'welcomeTeacher',
    },
    {
      title: 'First steps',
      subtitle: '',
      menuTitle: 'Welcome teacher!',
      index: 1,
      finished: false,
      id: 'completeTeacher',
    },
  ];
};

export const implementationOptions = [
  {
    value: 'small',
    label: 'Small group',
    description: 'Starting the GTT with a small group, potentially expanding in the future',
  },
  {
    value: '<20',
    label: 'Whole school (small)',
    description: 'Starting the GTT with all teachers (less than 20 teachers)',
  },
  {
    value: '>20',
    label: 'Whole school (large)',
    description: 'Starting the GTT with all teachers (more than 20 teachers)',
  },
];

export interface OnboardingBodyInterface {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  steps: onboardingStepInterface[] | [];
  user: Me | undefined;
  setOnboarding: React.Dispatch<React.SetStateAction<'school' | 'teacher' | undefined>>;
  version: 'school' | 'teacher' | undefined;
  setConfetti: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface OnboardingBodyContentInterface {
  type:
    | 'welcome'
    | 'academic_year'
    | 'terms'
    | 'implementation_intention'
    | 'registration'
    | 'complete'
    | 'welcomeTeacher'
    | 'completeTeacher';
  data: undefined | Array<any>;
  user: Me | undefined;
  setShowSkipButton: React.Dispatch<React.SetStateAction<boolean>>;
  setShowContinueButton: React.Dispatch<React.SetStateAction<boolean>>;
  reason: string | undefined;
  setReason: React.Dispatch<React.SetStateAction<string | undefined>>;
  copied: boolean;
  setCopied: React.Dispatch<React.SetStateAction<boolean>>;
  version: 'school' | 'teacher' | undefined;
}

export interface OnboardingChecklistType {
  type: 'school' | 'teacher';
  setAtLeastOneValueChecked: React.Dispatch<React.SetStateAction<boolean>>;
  link?: string;
  me: Me;
  thinkificLink?: string;
}

export interface OnboardingType {
  setOnboarding: React.Dispatch<React.SetStateAction<'school' | 'teacher' | undefined>>;
  onboarding: 'school' | 'teacher' | undefined;
  onboardingTracking: any;
  setConfetti: React.Dispatch<React.SetStateAction<boolean>>;
}

export const isAtLeastOneValueTrue = (obj: any) => {
  if (obj) {
    return Object.entries(obj)
      .filter(([key, _]) => key !== 'dashboard_onboarding_confirm')
      .some(([_, value]) => value === true);
  }
  return false;
};

export const areAllValuesTrue = (obj: any) => {
  if (obj) {
    return Object.entries(obj)
      .filter(([key, _]) => key !== 'dashboard_onboarding_confirm')
      .every(([_, value]) => value === true);
  }
  return false;
};

export interface checklistItem {
  title: string;
  value: JSX.Element | string;
  keyword?: string;
  finished?: boolean;
  id?: string;
}
