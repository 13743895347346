import React, { useEffect, useState } from 'react';
import Slider from './components/slider';
import {
  darkThemeBorderColor,
  darkThemeColor,
  darkThemeTextColor,
  lightThemeBorderColor,
  lightThemeColor,
  lightThemeTextColor,
} from './resources/resources';
import Icon from '../../../../../global/icon/icon';
import Tooltip from '../../../../../global/tooltip/tooltip';

const SelfStudyPage = ({
  resource,
  fullPage,
  setFullPage,
  theme,
  setTheme,
}: {
  resource?: any;
  fullPage?: boolean;
  setFullPage?: any;
  theme?: string;
  setTheme?: any;
}) => {
  const [progress, setProgress] = useState<string | number>('loading');

  useEffect(() => {
    if (resource) {
      const arrayLength = resource.pages?.length ?? 1;
      let completedPagesCount = 0;
      resource?.pages?.forEach((item: any) => {
        if (item.pageTrackings && item.pageTrackings.completed === true) {
          completedPagesCount++;
        }
      });
      const calculatedProgress = Math.round((completedPagesCount / arrayLength) * 100);
      setProgress(calculatedProgress ?? 0);
    }
  }, [resource]);

  return (
    <>
      {progress === 'loading' || resource.pages?.length < 1 ? (
        <div className={'p-4'}>
          {resource.pages?.length < 1 ? 'Error... Please contact the EBE team!' : 'Loading...'}
        </div>
      ) : (
        <div className={` ${fullPage ? 'w-full h-full ' : ''}`}>
          <div className={`bg-white ${!fullPage ? 'container-shadow section-container ' : 'flex w-full h-full '}`}>
            <div className={`m-auto p-2 w-5/6`}>
              <div
                className={'flex w-full rounded-t-md'}
                id={'banner'}
                style={{
                  backgroundColor: theme === 'light' ? lightThemeColor : darkThemeColor,
                  borderTop: `solid ${theme === 'light' ? lightThemeBorderColor : darkThemeBorderColor}`,
                  borderLeft: `solid ${theme === 'light' ? lightThemeBorderColor : darkThemeBorderColor}`,
                  borderRight: `solid ${theme === 'light' ? lightThemeBorderColor : darkThemeBorderColor}`,
                }}
              >
                <p
                  style={{ color: theme === 'light' ? lightThemeTextColor : darkThemeTextColor }}
                  className={'ml-2 mt-2 text-xs font-semibold'}
                >
                  {resource?.title}
                </p>
                {resource.description && (
                  <p
                    style={{ color: theme === 'light' ? lightThemeTextColor : darkThemeTextColor }}
                    className={'ml-12 mt-2 text-xs font-semibold'}
                  >
                    {resource.description}
                  </p>
                )}
                <div className="flex gap-2 ml-auto pt-0.5">
                  <Tooltip content={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}>
                    <Icon
                      style={{
                        color: theme === 'light' ? lightThemeTextColor : darkThemeTextColor,
                        borderColor: theme === 'light' ? lightThemeBorderColor : darkThemeTextColor,
                      }}
                      className={'border rounded-full'}
                      icon={theme === 'light' ? 'Moon' : 'BrightnessHighFill'}
                      elementSize={30}
                      onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
                    />
                  </Tooltip>
                  <Tooltip content={`${fullPage ? 'Exit' : 'Enter'} the full-screen mode`}>
                    <Icon
                      style={{
                        color: theme === 'light' ? lightThemeTextColor : darkThemeTextColor,
                        borderColor: theme === 'light' ? lightThemeBorderColor : darkThemeTextColor,
                      }}
                      className={'border rounded-full'}
                      icon={fullPage ? 'FullscreenExit' : 'Fullscreen'}
                      elementSize={30}
                      onClick={() => setFullPage(!fullPage)}
                    />
                  </Tooltip>
                </div>
              </div>
              <div
                className={'rounded-b-lg'}
                style={{
                  borderBottom: `solid ${theme === 'light' ? lightThemeBorderColor : darkThemeBorderColor}`,
                  borderLeft: `solid ${theme === 'light' ? lightThemeBorderColor : darkThemeBorderColor}`,
                  borderRight: `solid ${theme === 'light' ? lightThemeBorderColor : darkThemeBorderColor}`,
                }}
              >
                <div
                  className={' h-40 w-full h-full border-4 p-0.5 rounded-b-md'}
                  style={{
                    backgroundColor: theme === 'light' ? lightThemeColor : darkThemeColor,
                    borderColor: theme === 'light' ? lightThemeColor : darkThemeColor,
                  }}
                >
                  <Slider
                    theme={theme}
                    progress={typeof progress === 'number' ? progress : 0}
                    fullPage={fullPage}
                    pages={resource?.pages}
                    banner={resource?.banner}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default SelfStudyPage;
