export const ROLE_SYS_ADMIN = 'ROLE_SYS_ADMIN';
export const ROLE_TEACHER = 'ROLE_TEACHER';
export const ROLE_MAT_COORDINATOR = 'ROLE_MAT_COORDINATOR';
export const ROLE_SCHOOL_COORDINATOR = 'ROLE_SCHOOL_COORDINATOR';
export const ROLE_TEACHER_FREE = 'ROLE_TEACHER_FREE';
export const ROLE_ASSISTANT_GT_COORDINATOR = 'ROLE_ASSISTANT_GT_COORDINATOR';
export const CURRICULUM_AREA_LEADER = 'Curriculum area leader';
export const OTHER_MIDDLE_LEADER = 'Other middle leader';
export const SCHOOL_SENIOR_LEADER = 'School senior leader';
export const HEADTEACHER_PRINCIPLE = 'Headteacher/principle';

export type RolesType =
  | typeof ROLE_SYS_ADMIN
  | typeof ROLE_TEACHER
  | typeof ROLE_MAT_COORDINATOR
  | typeof ROLE_SCHOOL_COORDINATOR
  | typeof ROLE_TEACHER_FREE
  | typeof ROLE_ASSISTANT_GT_COORDINATOR;

export const RoleDescriptions = [
  CURRICULUM_AREA_LEADER,
  OTHER_MIDDLE_LEADER,
  SCHOOL_SENIOR_LEADER,
  HEADTEACHER_PRINCIPLE
];

export const ROLE_LABELS = {
  [ROLE_SYS_ADMIN]: 'System admin',
  [ROLE_MAT_COORDINATOR]: 'Group admin',
  [ROLE_SCHOOL_COORDINATOR]: 'Great Teaching Coordinator',
  [ROLE_TEACHER]: 'Teacher',
  [ROLE_TEACHER_FREE]: 'GTT Starter',
  [ROLE_ASSISTANT_GT_COORDINATOR]: 'Assistant GT Coordinator',
};

export const ROUTE_ROLE_MAT_COORDINATOR = 'mat';
export const ROUTE_ROLE_SCHOOL_COORDINATOR = 'school';
export const ROUTE_ROLE_TEACHER = 'teacher';
export const ROUTE_ROLE_TEACHER_FREE = 'teacher-free';

export const SUBSCRIPTION_TYPE_BULK = 'bulk';
export const SUBSCRIPTION_TYPE_PAID = 'paid';

export const ROLES = [
  {
    label: ROLE_LABELS[ROLE_MAT_COORDINATOR],
    value: ROLE_MAT_COORDINATOR,
  },
  {
    label: ROLE_LABELS[ROLE_SCHOOL_COORDINATOR],
    value: ROLE_SCHOOL_COORDINATOR,
  },
  {
    label: ROLE_LABELS[ROLE_TEACHER],
    value: ROLE_TEACHER,
  },
  {
    label: ROLE_LABELS[ROLE_TEACHER_FREE],
    value: ROLE_TEACHER_FREE,
  },
];

export const transformRoleParamToApi = (role: string | null) => {
  switch (role) {
    case ROUTE_ROLE_MAT_COORDINATOR:
      return ROLE_MAT_COORDINATOR;
    case ROUTE_ROLE_SCHOOL_COORDINATOR:
      return ROLE_SCHOOL_COORDINATOR;
    case ROUTE_ROLE_TEACHER:
      return ROLE_TEACHER;
    case ROUTE_ROLE_TEACHER_FREE:
      return ROLE_TEACHER_FREE;
    default:
      return null;
  }
};

export const transformRoleApiToLinkParam = (role: string | null) => {
  switch (role) {
    case ROLE_MAT_COORDINATOR:
      return ROUTE_ROLE_SCHOOL_COORDINATOR;
    case ROLE_SCHOOL_COORDINATOR:
      return ROUTE_ROLE_TEACHER;
    default:
      return null;
  }
};

export const ALL_ROLES = [
  { value: 'ROLE_SYS_ADMIN', label: 'System admin' },
  { value: 'ROLE_MAT_COORDINATOR', label: 'Group admin' },
  { value: 'ROLE_SCHOOL_COORDINATOR', label: 'Great Teaching Coordinator' },
  { value: 'ROLE_TEACHER', label: 'Teacher' },
  { value: 'ROLE_TEACHER_FREE', label: 'GTT Starter' },
  { value: 'ROLE_ASSISTANT_GT_COORDINATOR', label: 'Assistant GT Coordinator' },
];

export const MAT_STATIC_PICTURE_USER = { email: 'jobs@evidencebased.education', schoolId: 146 };

//NIOT ROLES (TAGS)

export const TAG_MENTOR = 'MENTOR';
export const TAG_INDUCTION_LEAD = 'INDUCTION_LEAD';
export const TAG_ASSOCIATE_COLLEGE = 'ASSOCIATE_COLLEGE';
export const TAG_REGIONAL_LEAD = 'REGIONAL_LEAD';
export const TAG_NIOT = 'NIOT';
export const TAG_ECT = 'ECT';

export const NIOT_TAGS = [
  { value: 'MENTOR', label: 'Mentor' },
  { value: 'INDUCTION_LEAD', label: 'Induction lead' },
  { value: 'ASSOCIATE_COLLEGE', label: 'Associate college' },
  { value: 'REGIONAL_LEAD', label: 'Regional lead' },
  { value: 'NIOT', label: 'Niot' },
  { value: 'ECT', label: 'ECT' },
];

export const niotAdminAccounts = ['ASSOCIATE_COLLEGE', 'REGIONAL_LEAD', 'NIOT'];

export const niotRole = (data: any) => {
  if (data.is_niot) return TAG_NIOT;
  else if (data.is_regional_lead) {
    return TAG_REGIONAL_LEAD;
  } else if (data.is_associate_college) {
    return TAG_ASSOCIATE_COLLEGE;
  } else if (data.is_induction_lead) {
    return TAG_INDUCTION_LEAD;
  } else if (data.mentor) {
    return TAG_MENTOR;
  } else if (data.is_early_career_teacher) {
    return TAG_ECT;
  } else return 'null';
};

// STCP ROLES (TAGS)

export const TAG_STCP_COORDINATOR = 'STCP_COORDINATOR';
export const TAG_STCP_TEACHER = 'STCP_TEACHER';
export const TAG_STCP_ADMIN = 'STCP_ADMIN';

export const isStcp = (data: any) => {
  if (data.is_ceo) {
    if (data.role === ROLE_TEACHER && data.is_ceo_admin) return TAG_STCP_ADMIN;
    else if (data.role === ROLE_TEACHER) return TAG_STCP_TEACHER;
    else if (data.role === ROLE_SCHOOL_COORDINATOR) return TAG_STCP_COORDINATOR;
    else return false;
  } else return false;
};
