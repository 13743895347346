import dayjs from 'dayjs';
import Icon from '../../../global/icon/icon';
import Accordion from '../../../global/accordion/accordion';
import React, { BaseSyntheticEvent, createRef, useState } from 'react';
import { Meeting } from '../../../@types/Entity/Meeting';
import DefaultTable from '../../../global/table/defaultTable';
import Button from '../../../global/button/button';
import { Editor } from 'react-draft-wysiwyg';
import classNames from 'classnames';
import ButtonIcon from '../../../global/buttonIcon/buttonIcon';
import { Me } from '../../../@types/Entity/Me';
import Tooltip from '../../../global/tooltip/tooltip';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getDownloadMeetingFile,
  getMeeting,
  startOrBlockMeeting,
  updateMeeting,
  UpdateMeetingRequestBody,
} from '../../../service/api/groupsApi';
import { GROUP_MEETINGS_ACTIONS_TAGS, MEETING } from '../../../service/queryKeys';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Option } from '../../../@types/global';
import { Checkbox } from 'pretty-checkbox-react';
import EditActionsModal from './modals/EditActionsModal';
import AddFeedbackModal, { InstrumentSchoolClassOption } from './modals/AddFeedback.modal';
import CustomBarChart from '../../feedback/components/customBarChart';
import { processedElementData } from '../../feedback/sections/elementChartSection';
import { processedSummaryData } from '../../feedback/sections/summaryChartSection';
import routeBuilder from '../../../service/routeBuilder';
import { chain } from 'lodash';
import { LinkStyled } from '../../../global/link/link.styled';
import { TEAMS_MODE_CONTEXT_TEAMS_MODES } from '../../../contexts/TeamsContext/TeamsModeContext';
import { toast } from 'react-toastify';
import { niotRole, ROLE_TEACHER_FREE } from '../../../resources/roles';

export default function MeetingItem({ me, meeting: initialMeeting, group, teamsMode, index }: Props) {
  const isNiot = niotRole(me);

  const isFree = me?.role === ROLE_TEACHER_FREE;

  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(
    isFree ? false : null !== me && null !== initialMeeting.beingUpdatedBy && me.id === initialMeeting.beingUpdatedBy.id
  );
  const [editActionsModalData, setEditActionsModalData] = useState<{
    isOpen: boolean;
    actions: MeetingFormValues['actions'];
  }>({ isOpen: false, actions: [] });
  const [addFeedbackModalData, setAddFeedbackModalData] = useState<{ isOpen: boolean }>({ isOpen: false });
  const fileInputRef = createRef<HTMLInputElement>();

  const meetingQuery = useQuery([MEETING, { id: initialMeeting.id }], () => getMeeting(initialMeeting.id), {
    initialData: {
      data: initialMeeting,
    },
    staleTime: Infinity,
    refetchInterval: isOpen && !isEditing ? 5000 : Infinity,
    onSuccess: (data) => {
      setIsEditing(
        isFree ? false : null !== me && null !== data.data.beingUpdatedBy && me.id === data.data.beingUpdatedBy.id
      );
    },
  });

  const meeting = meetingQuery.data!.data;

  const startOrBlockMeetingMutation = useMutation(
    ({ startOrBlock }: { startOrBlock: 'start' | 'block' }) => startOrBlockMeeting(meeting.id, startOrBlock),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MEETING, { id: initialMeeting.id }]);
      },
      onError: () => {
        queryClient.invalidateQueries([MEETING, { id: initialMeeting.id }]);
      },
    }
  );

  const updateMeetingMutation = useMutation(
    ({ data, filesToAdd }: { data: UpdateMeetingRequestBody; filesToAdd?: File[] | undefined }) =>
      updateMeeting(meeting.id, data, filesToAdd),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MEETING, { id: initialMeeting.id }]);
        queryClient.invalidateQueries([GROUP_MEETINGS_ACTIONS_TAGS, { id: group }]);
      },
    }
  );

  const { control, handleSubmit, getValues, setValue, register } = useForm<MeetingFormValues>({
    defaultValues: {
      agendaItems: meeting.agendaItems,
      praiseStrength: EditorState.createWithContent(convertFromRaw(JSON.parse(meeting.praiseStrength))),
      probeAreas: EditorState.createWithContent(convertFromRaw(JSON.parse(meeting.probeAreas))),
      files: meeting.files,
      filesToBeRemoved: [],
      feedbacks: meeting.feedbacks.map((feedback) => ({
        id: feedback.id,
        type: feedback.type,
        instrumentSchoolClass:
          'video' !== feedback.type ? { label: feedback.title!, value: feedback.instrument_school_class_id! } : null,
        element:
          'video' !== feedback.type ? { label: feedback.data.chart_element, value: feedback.data.chart_element } : null,
        video: 'video' === feedback.type ? { label: feedback.data.title, value: feedback.data.id } : null,
      })),
      actions: meeting.actions.map((action) => ({
        id: action.id,
        title: action.title,
        tags: action.tags.map((tag) => ({ label: tag.title, value: tag.id })),
        assignedTo: { label: action?.assignedTo?.fullName ?? '', value: action?.assignedTo?.id },
        dueDate: action.dueDate,
      })),
      instructions1: meeting.celebrateSuccess ?? [],
      instructions2: meeting.shareChallenges ?? [],
    },
  });

  const agendaItemsFieldArray = useFieldArray({
    control: control,
    name: 'agendaItems',
  });

  const feedbacksFieldArray = useFieldArray({
    control: control,
    name: 'feedbacks',
  });

  const filesFieldArray = useFieldArray({
    control: control,
    name: 'files',
  });

  const handleDataSubmit = (event?: BaseSyntheticEvent, finish?: boolean, addFiles?: boolean) => {
    toast.success('Meeting updated successfully.');
    handleSubmit((values) =>
      updateMeetingMutation.mutate({
        data: {
          agendaItems: values.agendaItems.map((agendaItem) => ({
            id: agendaItem.id,
            isChecked: agendaItem.isChecked,
          })),
          praiseStrength: JSON.stringify(convertToRaw(values.praiseStrength.getCurrentContent())),
          probeAreas: JSON.stringify(convertToRaw(values.probeAreas.getCurrentContent())),
          filesToBeRemoved: values.filesToBeRemoved,
          feedbacks: values.feedbacks.map((feedback) => ({
            id: feedback.id,
            instrumentSchoolClass: feedback.instrumentSchoolClass?.value ?? null,
            video: feedback.video?.value ?? null,
            element: feedback.video?.value ?? null,
          })),
          actions: values.actions.map((action) => ({
            id: action.id,
            title: action.title,
            tags: action.tags.map((tagOption) => ({
              id: tagOption.__isNew__ ? null : tagOption.value,
              title: tagOption.label,
            })),
            assignedTo: action.assignedTo!.value,
            dueDate: action.dueDate!,
          })),
          isFinishing: finish,
        },
        filesToAdd: addFiles
          ? chain(values.files)
              .filter((fileData) => null != fileData.file)
              .map((fileData) => fileData.file!)
              .value()
          : undefined,
      })
    )(event);
  };

  const MeetingStatus = () => {
    if (!meeting.isStarted) {
      return (
        <Button
          size={'xsm'}
          disabled={isFree}
          mainColor={'success'}
          onClick={(event) => {
            event.stopPropagation();

            startOrBlockMeetingMutation.mutate({ startOrBlock: 'start' });

            setIsOpen(true);
          }}
        >
          Start meeting
        </Button>
      );
    }

    const isBeingEditedByOtherUser =
      null !== meeting.beingUpdatedBy && null != me && me.id !== meeting.beingUpdatedBy.id;

    if (!meeting.isFinished) {
      return (
        <Tooltip disabled={!isBeingEditedByOtherUser} content={'This meeting was started by a colleague.'}>
          <Button
            disabled={isFree}
            size={'xsm'}
            mainColor={!isBeingEditedByOtherUser ? 'danger' : 'muted'}
            onClick={(event) => {
              event.stopPropagation();

              if (isBeingEditedByOtherUser) {
                return;
              }

              handleDataSubmit(event, true, false);
            }}
          >
            Finish meeting
          </Button>
        </Tooltip>
      );
    }

    if (null === meeting.beingUpdatedBy) {
      return (
        <div className={'flex'}>
          <ButtonIcon
            icon={'Pencil'}
            size={'xsm'}
            className={'mr-2'}
            isFree
            disabled={isBeingEditedByOtherUser || ROLE_TEACHER_FREE === me?.role}
            label={!isBeingEditedByOtherUser ? 'Edit' : 'This meeting is currently being edited by a colleague.'}
            onClick={(event) => {
              event.stopPropagation();

              startOrBlockMeetingMutation.mutate({ startOrBlock: 'block' });

              setIsOpen(true);
            }}
          />
          <Icon icon={'CheckCircle'} color={'success'} container={false} />
        </div>
      );
    }

    if (null !== me && me.id === meeting.beingUpdatedBy.id) {
      return (
        <div className={'flex'}>
          <ButtonIcon
            icon={'Save'}
            size={'xsm'}
            className={'mr-2'}
            isFree
            label={'Save'}
            onClick={(event) => {
              event.stopPropagation();

              handleDataSubmit(event, true, false);
            }}
          />
          <Icon icon={'CheckCircle'} color={'success'} container={false} />
        </div>
      );
    }

    return <Icon icon={'CheckCircle'} color={'success'} container={false} />;
  };

  return (
    <>
      {addFeedbackModalData.isOpen && (
        <AddFeedbackModal
          toggle={() => setAddFeedbackModalData({ isOpen: false })}
          onSubmit={(feedback) => {
            feedbacksFieldArray.append(feedback);

            setAddFeedbackModalData({ isOpen: false });
          }}
        />
      )}

      {editActionsModalData.isOpen && (
        <EditActionsModal
          actions={editActionsModalData.actions}
          toggle={() => setEditActionsModalData({ isOpen: false, actions: [] })}
          onSubmit={(actions) => {
            setValue('actions', actions);
            setEditActionsModalData({ isOpen: false, actions: [] });
            handleDataSubmit(undefined, false, false);
          }}
          group={group}
        />
      )}

      <Accordion
        title={
          <div className={'grid grid-cols-3'}>
            <div className={'font-bold'}>{meeting.title}</div>
            <div>
              {isFree && index != undefined ? <>Term {index + 1}</> : dayjs(meeting.scheduledAt).format('ddd DD MMM')}
            </div>
            <div className={'flex justify-end'}>
              <MeetingStatus />
            </div>
          </div>
        }
        isOpen={isOpen}
        handleOpen={() => setIsOpen((prev) => !prev)}
      >
        <div className="grid grid-cols-3 gap-4 mb-8">
          <div>
            <span className={'font-bold'}>Prerequisites</span>
            <ul className={'list-disc list-inside mt-2'}>
              {meeting.prerequisites.map((prerequisite, index) => (
                <li className={'p-2'} key={index}>
                  {prerequisite.title}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <span className={'font-bold'}>Agenda</span>
            <ul className={'list-none list-inside'}>
              {agendaItemsFieldArray.fields.map((agendaItem, index) => (
                <div className="flex mt-2" key={index}>
                  <li className={''}>
                    {index + 1}.{' '}
                    <>
                      {!isFree && (
                        <Checkbox
                          {...register(`agendaItems.${index}.isChecked` as const)}
                          id={`agendaItem_${agendaItem.id}`}
                          color={'primary'}
                          locked={!isEditing}
                        />
                      )}
                    </>
                    {/*<p className={'break-word'}>*/}
                    {agendaItem.title}
                    {/*</p>*/}
                  </li>
                </div>
              ))}
            </ul>
          </div>
          <div>
            <span className={'font-bold'}>Resources</span>
            <ul className={'list-disc list-inside'}>
              {meeting.resources.map((resource) => (
                <li key={resource.id}>
                  {resource.url ? (
                    <LinkStyled href={resource.url} target={'_blank'}>
                      {resource.title}
                    </LinkStyled>
                  ) : (
                    <LinkStyled onClick={() => getDownloadMeetingFile(resource.id!, resource.name!)}>
                      {resource.name}
                    </LinkStyled>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-10 gap-5">
          <div className={'grid grid-cols-2 gap-5 my-2'}>
            <div>
              <span className={'font-bold'}>{isNiot != 'null' ? 'Praise strengths' : 'Celebrate success'}</span>
            </div>
            <div>
              <span className={'font-bold'}>
                {isNiot != 'null' ? 'Probe areas for development' : 'Share challenges and potential improvements'}
              </span>
            </div>
          </div>
          {isNiot === 'null' && (
            <div className={'grid grid-cols-2 gap-5 my-2'}>
              <div>
                <div className="ml-8 my-2">
                  <ul className={'list-disc'}>
                    {meeting.celebrateSuccess?.map((instruction, index) => (
                      <li key={index}>{instruction.instruction}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div>
                <div className="ml-8 my-2">
                  <ul className={'list-disc'}>
                    {meeting.shareChallenges?.map((instruction, index) => (
                      <li key={index}>{instruction.instruction}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
          <div className={'grid grid-cols-2 gap-5 my-2'}>
            <div>
              <div className={''}>
                <div className={'min-h-60 2xl:h-60'} style={{ opacity: !isEditing ? 0.4 : 1 }}>
                  <Controller
                    control={control}
                    name={'praiseStrength'}
                    render={({ field: { value, onChange } }) => (
                      <Editor
                        toolbarHidden={!isEditing}
                        toolbar={{
                          options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
                          inline: {
                            options: ['bold', 'italic', 'underline'],
                          },
                        }}
                        readOnly={!isEditing}
                        wrapperClassName={`h-full pathways-section-content ${classNames({
                          'border border-gray-400': !isEditing,
                        })}`}
                        editorClassName={'h-5/6 p-3 bg-white'}
                        onEditorStateChange={(value) => onChange(value)}
                        editorState={value}
                      />
                    )}
                  />
                </div>
                {isEditing && (
                  <div className={'my-4 2xl:mt-10 flex'}>
                    <Tooltip content={'Save'} disabled={!isEditing}>
                      <Button
                        className={'ml-auto'}
                        disabled={!isEditing}
                        size={'xsm'}
                        onClick={() => handleDataSubmit(undefined, false, false)}
                      >
                        <Icon icon={'Save'} elementSize={50} className={'-my-4 -mx-5 -p-4'}></Icon>
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={'w-full'}>
                <div className={'min-h-60 2xl:h-60'} style={{ opacity: !isEditing ? 0.4 : 1 }}>
                  <Controller
                    control={control}
                    name={'probeAreas'}
                    render={({ field: { value, onChange } }) => (
                      <Editor
                        toolbarHidden={!isEditing}
                        toolbar={{
                          options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
                          inline: {
                            options: ['bold', 'italic', 'underline'],
                          },
                        }}
                        readOnly={!isEditing}
                        wrapperClassName={`h-full pathways-section-content ${classNames({
                          'border border-gray-400': !isEditing,
                        })}`}
                        editorClassName={'h-5/6 p-3 bg-white'}
                        onEditorStateChange={(value) => onChange(value)}
                        editorState={value}
                      />
                    )}
                  />
                </div>
                {isEditing && (
                  <div className={'my-4 2xl:mt-10 flex'}>
                    <Tooltip content={'Save'} disabled={!isEditing}>
                      <Button
                        className={'ml-auto'}
                        disabled={!isEditing}
                        size={'xsm'}
                        onClick={() => handleDataSubmit(undefined, false, false)}
                      >
                        <Icon icon={'Save'} elementSize={50} className={'-my-4 -mx-5 -p-4'}></Icon>
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={'mt-10'}>
          <div className={'flex mb-2'}>
            <div>
              <span className={'font-bold'}>Set precise actions</span>
            </div>
            <div className={'ml-2'}>
              {isEditing && (
                <ButtonIcon
                  icon={'Plus'}
                  size={'xsm'}
                  label={`${meeting.actions.length > 0 ? 'Edit' : 'Add'} actions`}
                  onClick={() => setEditActionsModalData({ isOpen: true, actions: getValues('actions') })}
                />
              )}
            </div>
          </div>

          <Controller
            control={control}
            name={'actions'}
            render={({ field: { value } }) => (
              <DefaultTable
                id={`meeting-${meeting.id}-actions`}
                noDataMessage={'This meeting does not have actions yet.'}
                columns={[
                  {
                    id: 'title',
                    label: 'Action',
                  },
                  {
                    id: 'tags',
                    label: 'Tags',
                  },
                  {
                    id: 'assignedTo',
                    label: 'Assigned to',
                  },
                  {
                    id: 'dueDate',
                    label: 'Due date',
                  },
                ]}
                rows={value.map((action, index) => ({
                  id: `action_${index}`,
                  cells: [
                    { id: 'title', content: action.title },
                    { id: 'tags', content: action.tags.map((tag) => tag.label).join(', ') },
                    { id: 'assignedTo', content: action.assignedTo?.label },
                    { id: 'dueDate', content: dayjs(action.dueDate!, 'YYYY-MM-DD').format('DD/MM/YYYY') },
                  ],
                }))}
              />
            )}
          />
        </div>

        <div className={'mt-10'}>
          <div className={'flex mb-2'}>
            <div>
              <span className={'font-bold'}>Files</span>
            </div>
            <div className={'ml-2'}>
              {isEditing && (
                <>
                  <input
                    ref={fileInputRef}
                    type={'file'}
                    className={'hidden'}
                    onChange={(event) => {
                      const files = event.target.files;

                      if (null === files) {
                        return;
                      }

                      const file = files[0];

                      filesFieldArray.append({
                        file: file,
                        originalName: file.name,
                        mimeType: file.type,
                      });

                      handleDataSubmit(undefined, false, true);
                    }}
                  />
                  <ButtonIcon
                    icon={'Plus'}
                    size={'xsm'}
                    label={'Add files'}
                    onClick={() => fileInputRef.current?.click()}
                  />
                </>
              )}
            </div>
          </div>

          <Controller
            control={control}
            name={'files'}
            render={({ field: { value } }) => (
              <DefaultTable
                id={`meeting-${meeting.id}-files`}
                noDataMessage={'This meeting does not have files yet.'}
                columns={[
                  {
                    id: 'title',
                    label: 'Name',
                  },
                  {
                    id: 'type',
                    label: 'Type',
                  },
                  ...(isEditing
                    ? [
                        {
                          id: 'options',
                          label: '',
                        },
                      ]
                    : []),
                ]}
                rows={value.map((file, index) => ({
                  id: `file_${index}`,
                  cells: [
                    {
                      id: 'title',
                      content:
                        null != file.id ? (
                          <LinkStyled onClick={() => getDownloadMeetingFile(file.id!, file.originalName)}>
                            {file.originalName}
                          </LinkStyled>
                        ) : (
                          file.originalName
                        ),
                    },
                    { id: 'type', content: file.mimeType },
                    ...(isEditing
                      ? [
                          {
                            id: 'options',
                            content: (
                              <ButtonIcon
                                icon={'Trash'}
                                isFree
                                label={'Delete file'}
                                onClick={() => {
                                  filesFieldArray.remove(index);

                                  if (null != file.id) {
                                    setValue('filesToBeRemoved', [...getValues('filesToBeRemoved'), file.id]);
                                  }
                                  handleDataSubmit(undefined, false, true);
                                }}
                              />
                            ),
                          },
                        ]
                      : []),
                  ],
                }))}
              />
            )}
          />
        </div>

        {TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams === teamsMode && (
          <div className={'mt-10'}>
            <div className={'flex mb-2'}>
              <div>
                <span className={'font-bold'}>Feedback</span>
              </div>
              <div className={'ml-2'}>
                {isEditing && (
                  <ButtonIcon
                    icon={'Plus'}
                    size={'xsm'}
                    label={'Add feedback'}
                    onClick={() => setAddFeedbackModalData({ isOpen: true })}
                  />
                )}
              </div>
            </div>

            {!isEditing ? (
              <>
                {0 === meeting.feedbacks.length && 'This meeting does not have feedback yet.'}
                {meeting.feedbacks.map((feedback) => {
                  if ('chart' === feedback.type) {
                    return (
                      <div className={'w-full mt-5 overflow-x-auto p-5 border-gray-100 border-2'} key={Math.random()}>
                        <div className={'flex justify-between mb-5'}>
                          <span className={'font-bold'}>{feedback.data.options[0].name}</span>
                        </div>
                        <CustomBarChart
                          data={
                            feedback.chart_element
                              ? processedElementData(feedback.data, feedback.chart_element, [
                                  feedback.data.options[0].id,
                                ])
                              : processedSummaryData(
                                  feedback.data,
                                  [feedback.data.options[0].id],
                                  feedback.survey_version
                                )
                          }
                          dataKey={feedback.chart_element ? 'question' : 'element'}
                          id={`summary_${feedback.id}`}
                        />
                      </div>
                    );
                  }

                  return (
                    <div className={'w-full sm:w-96 mt-5 p-5 border-gray-100 border-2'} key={Math.random()}>
                      <div className={'mb-5'}>
                        <div className={'flex justify-between'}>
                          <span className={'font-bold'}>{feedback.data.title}</span>
                        </div>
                        <span className={'text-muted'}>
                          {feedback.data.author} - {feedback.data.created_at}
                        </span>
                      </div>
                      <img
                        src={feedback.data.thumbnail_path}
                        alt={feedback.data.title}
                        className={'w-full cursor-pointer'}
                        onClick={() => {
                          window.open(
                            `${window.location.origin}${routeBuilder('videoPlayer').generate(feedback.data.id)}`,
                            '_blank'
                          );
                        }}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <span className="text-sm">While editing, only a list of feedback items will be shown.</span>
                <Controller
                  control={control}
                  name={'feedbacks'}
                  render={({ field: { value } }) => (
                    <DefaultTable
                      id={`meeting-${meeting.id}-feedbacks`}
                      noDataMessage={'This meeting does not have feedback yet.'}
                      columns={[
                        {
                          id: 'title',
                          label: 'Feedback',
                        },
                        {
                          id: 'type',
                          label: 'Type',
                        },
                        ...(isEditing
                          ? [
                              {
                                id: 'options',
                                label: '',
                              },
                            ]
                          : []),
                      ]}
                      rows={value.map((feedback, index) => {
                        const title =
                          null !== feedback.video ? feedback.video?.label : feedback.instrumentSchoolClass?.label;
                        const type = null !== feedback.video ? 'video' : 'survey';

                        return {
                          id: `feedback_${index}`,
                          cells: [
                            { id: 'title', content: title },
                            { id: 'type', content: type },
                            ...(isEditing
                              ? [
                                  {
                                    id: 'options',
                                    content: (
                                      <ButtonIcon
                                        icon={'Trash'}
                                        isFree
                                        label={'Delete feedback'}
                                        onClick={() => feedbacksFieldArray.remove(index)}
                                      />
                                    ),
                                  },
                                ]
                              : []),
                          ],
                        };
                      })}
                    />
                  )}
                />
              </>
            )}
          </div>
        )}
      </Accordion>
    </>
  );
}

interface Props {
  me: Me | null;
  meeting: Meeting;
  group: string;
  teamsMode: string;
  index?: number;
}

export interface MeetingFormValues {
  agendaItems: {
    id: string;
    title: string;
    isChecked: boolean;
  }[];
  praiseStrength: EditorState;
  probeAreas: EditorState;
  files: {
    id?: string;
    originalName: string;
    mimeType: string;
    file?: File;
  }[];
  filesToBeRemoved: string[];
  feedbacks: {
    id: string | null;
    type: string;
    instrumentSchoolClass: InstrumentSchoolClassOption | null;
    video: Option | null;
    element: Option | null;
  }[];
  actions: {
    id: string | null;
    title: string;
    tags: Option[];
    assignedTo: Option | null;
    dueDate: string | null;
  }[];
  instructions1?: Array<string>;
  instructions2?: Array<string>;
}
