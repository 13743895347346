import React from 'react';
import { ModalProps } from '../../../global/messages/modal/modal.types';
import ModalComponents from '../../../global/messages/modal/modal.components';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { reassignGroup } from '../../../service/api/groupsApi';
import { MENTORS, USERS_MONITORING_LIST } from '../../../service/queryKeys';
import { Group } from '../../../@types/Entity/Group';
import Selector from '../../../form/select/selector';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { getSchoolMentors } from "../../../service/api/adminApi";
import Modal from '../../../global/messages/modal/modal.components';
import { SelectOptionType } from '../../../service/types';

export default function ReassignGroupModal({ toggle, group }: Props) {
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(Yup.object().shape({
      mentors: Yup.array().min(1).required()
    })),
    defaultValues: {
      mentors: [] as SelectOptionType<string>[]
    },
  });

  const reassignGroupMutation = useMutation(reassignGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries([USERS_MONITORING_LIST]);
      toast.success('Team reassigned');
      toggle(false);
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      toast.error(error.response?.data.message ?? 'An error has occurred.');
    },
  });

  const schoolMentorsQuery = useQuery([MENTORS], () => getSchoolMentors(), {
    staleTime: Infinity,
    select: (data) => data.data.data,
  });

  return (
    <Modal preventCloseOnClickOnMask>
      <form
        onSubmit={handleSubmit((values) =>
          reassignGroupMutation.mutate({
            groupId: group.id,
            mentors: values.mentors.map((mentor) => mentor.value)
          })
        )}
      >
        <ModalComponents.Body>
          <div className={'field-mb'}>
            <Controller
              control={control}
              name={'mentors'}
              render={({ field: { onChange, ...field } }) => (
                <Selector
                  {...field}
                  id={'mentors'}
                  label={'Mentors'}
                  error={errors.mentors?.message}
                  options={
                    (schoolMentorsQuery.data ?? []).map((teacher) => ({
                      label: teacher.fullName,
                      value: teacher.id,
                    })) ?? []
                  }
                  onChange={(option: SelectOptionType<string>) => onChange([option])}
                  isLoading={!schoolMentorsQuery.isFetched || schoolMentorsQuery.isFetching}
                  disabled={!schoolMentorsQuery.isFetched || schoolMentorsQuery.isFetching}
                />
              )}
            />
          </div>
        </ModalComponents.Body>
        <ModalComponents.Footer>
          <ModalComponents.CancelButton onClick={() => toggle(false)} />
          <ModalComponents.ConfirmButton
            label={
              isSubmitting || reassignGroupMutation.isLoading ? 'Loading...' : 'Reassign team'
            }
            type={'submit'}
            disabled={isSubmitting || reassignGroupMutation.isLoading}
          />
        </ModalComponents.Footer>
      </form>
    </Modal>
  );
}

interface Props extends Pick<ModalProps, 'toggle'> {
  group: Group;
}
