import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import DefaultTable from '../../../global/table/defaultTable';
import { useQuery } from 'react-query';
import { ECT_MEETING } from '../../../service/queryKeys';
import { getMeetingsForEct } from '../../../service/api/groupsApi';
import { formatDateTime, isThereFocus, MEETING_MODAL_COLUMNS } from '../resources/resources';

const MeetingsModal = ({ toggle, data }: ModalToggleProps & { data: any }) => {
  const { data: meetingsEct } = useQuery([ECT_MEETING, { id: data.id }], () => getMeetingsForEct(data.id), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
  });

  return (
    <Modal>
      <Modal.Body>
        <DefaultTable
          showCounter={false}
          id={'meetings_modal'}
          columns={MEETING_MODAL_COLUMNS}
          rows={(meetingsEct ?? []).map((data: any) => {
            return {
              id: `activity-row-${data?.id}`,
              cells: [
                {
                  id: 'title',
                  content: <div className={'pl-4'}>{data?.title}</div>,
                },
                {
                  id: 'scheduled_at',
                  content: (
                    <div style={{ minWidth: '160px' }}>{data?.scheduledAt ? formatDateTime(data.scheduledAt) : ''}</div>
                  ),
                },
                {
                  id: 'completed_at',
                  content: (
                    <div style={{ minWidth: '160px' }}>{data?.finishedAt ? formatDateTime(data.finishedAt) : '-'}</div>
                  ),
                },
                {
                  id: 'action_and_focus',
                  content: (
                    <div style={{ maxWidth: '550px' }}>
                      <table style={{ width: '100%' }}>
                        {data?.actions?.map((t: any, index: number) => (
                          <tr key={index}>
                            <td style={{ width: isThereFocus(data) ? '50%' : '75%' }}>{t?.title}</td>
                            <td style={{ minWidth: '25%', maxWidth: '50%' }}>
                              {t?.tags?.length > 0 ? (
                                t.tags.map((t: any, index: number) => {
                                  if (index < 2)
                                    return (
                                      <p
                                        style={{
                                          backgroundColor: '#e2c4ff',
                                          fontSize: 12,
                                          borderColor: '#530f93',
                                          color: '#530f93',
                                        }}
                                        className={'uppercase mx-0.5 text-center px-1 rounded border inline-block'}
                                      >
                                        {t.title}
                                      </p>
                                    );
                                })
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  ),
                },
              ],
            };
          })}
          noDataMessage={`When your mentor and ECT complete a meeting, you'll see a summary of their activities here`}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} label={'Close'} />
      </Modal.Footer>
    </Modal>
  );
};
export default MeetingsModal;
