import React, { useState } from 'react';
import DashboardLayout from '../../layout/dashboard';
import Choice from '../../form/choice/choice';
import Button from '../../global/button/button';
import ClassFormModal from './modals/classFormModal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getSchoolClasses, postOrPutSchoolClass } from '../../service/api';
import useModal from '../../hooks/useModal';
import { toast } from 'react-toastify';
import DefaultTable from '../../global/table/defaultTable';
import { ArchivedName } from '../../global/table/components';
import { ColumnType } from '../../global/table/table.types';
import { ActionButton } from '../../global/buttonIcon/common';
import { SliceText } from '../../global/tooltip/common';
import { Section } from '../common';
import { INSTRUMENT_OPTIONS, SCHOOL_CLASSES } from '../../service/queryKeys';
import { AddButton } from '../../global/button/common';

const COLUMNS: ColumnType[] = [
  { id: 'name', label: 'Class name', isSortable: true },
  { id: 'subject', label: 'Class subject', isSortable: true },
  { id: 'group', label: 'Year group' },
  { id: 'info', label: 'Additional info' },
  { id: 'actions', label: 'Actions' },
];

const ClassManagementPage = () => {
  const queryClient = useQueryClient();
  const [showArchived, setShowArchived] = useState(false);

  const { data } = useQuery(SCHOOL_CLASSES, getSchoolClasses, {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
  });

  const mutation = useMutation(postOrPutSchoolClass, {
    onSuccess: () => {
      queryClient.invalidateQueries(SCHOOL_CLASSES);
      queryClient.invalidateQueries(INSTRUMENT_OPTIONS);
    },
  });

  const [modal, toggleModal, setModalProps] = useModal((props: any) => <ClassFormModal {...props} />, {
    mutation,
  });

  const filteredClasses = () => {
    if (showArchived) {
      return data;
    }

    return data.filter((item: Record<any, any>) => !item.archived);
  };

  const printAdditionalInfo = (value: string | null) => {
    if (value) {
      return <SliceText value={value} />;
    }

    return <i className={'text-muted'}>—</i>;
  };

  return (
    <React.Fragment>
      {modal}
      {/* Content */}
      <DashboardLayout
        title={'Classes'}
        pageAction={
          <AddButton
            label={'Add class'}
            onClick={() => {
              setModalProps({
                title: 'Add new class',
                description: '',
                classToEdit: {},
              });
              toggleModal(true);
            }}
          />
        }
      >
        <Section>
          <div className={'flex justify-end items-center mb-5'}>
            <Choice
              id={'archived-classes'}
              label={'View archived classes'}
              type={'switch'}
              checked={showArchived && data.some((element: Record<any, any>) => element.archived)}
              onChange={() => setShowArchived(!showArchived)}
              className={'pretty-no-margin'}
              disabled={!data.some((element: Record<any, any>) => element.archived)}
            />
          </div>
          <DefaultTable
            id={'class_management'}
            itemCounter={{
              single: 'class',
              plural: 'classes',
            }}
            columns={COLUMNS}
            rows={
              0 === filteredClasses().length
                ? []
                : filteredClasses().map((schoolClass: SchoolClassType) => {
                    return {
                      id: schoolClass.id,
                      class: schoolClass.archived ? 'archived' : '',
                      cells: [
                        {
                          id: 'name',
                          content: (
                            <ArchivedName archived={schoolClass.archived} title={schoolClass.name} item={'class'} />
                          ),
                          value: schoolClass.name,
                        },
                        {
                          id: 'subject',
                          content: schoolClass.subject,
                        },
                        {
                          id: 'year',
                          content: schoolClass.year_group,
                        },
                        {
                          id: 'info',
                          content: printAdditionalInfo(schoolClass.additional_info),
                        },
                        {
                          id: 'actions',
                          class: 'actions',
                          content: (
                            <ul className={'actions-list'}>
                              <li>
                                <ActionButton.Edit
                                  archived={schoolClass.archived}
                                  onClick={() => {
                                    setModalProps({
                                      classToEdit: { ...schoolClass },
                                      title: 'Edit this class',
                                      description: (
                                        <ArchivedName
                                          archived={schoolClass.archived}
                                          title={schoolClass.name}
                                          item={'class'}
                                        />
                                      ),
                                    });
                                    toggleModal(true);
                                  }}
                                />
                              </li>
                              <li>
                                <ActionButton.Change
                                  archived={schoolClass.archived}
                                  state={schoolClass.archived}
                                  states={{
                                    on: { label: 'Archive', icon: 'archive' },
                                    off: { label: 'Unarchive', icon: 'unarchive' },
                                  }}
                                  onClick={() => {
                                    if (mutation.isLoading) {
                                      return;
                                    }

                                    mutation
                                      .mutateAsync({ id: schoolClass.id, archived: !schoolClass.archived })
                                      .then(() => {
                                        toast.success(
                                          `${schoolClass.name} has been ${
                                            schoolClass.archived ? 'unarchived' : 'archived'
                                          }.`
                                        );
                                      });
                                  }}
                                />
                              </li>
                            </ul>
                          ),
                        },
                      ],
                    };
                  })
            }
            noDataMessage={
              <>
                Start adding classes clicking on &quot;Add class&quot; button.
                {0 < data.length && (
                  <p>
                    <strong>You have archived classes.</strong>{' '}
                    <Button
                      onClick={() => setShowArchived(!showArchived)}
                      asLink
                      style={{ fontStyle: 'italic', padding: 5 }}
                    >
                      Click here
                    </Button>{' '}
                    to see them.
                  </p>
                )}
              </>
            }
          />
        </Section>
      </DashboardLayout>
    </React.Fragment>
  );
};

type SchoolClassType = {
  id: string;
  name: string;
  subject: string;
  year_group: string;
  additional_info: string | null;
  archived: boolean;
};

export default ClassManagementPage;
