export const ASSOCIATED_COLLEGES = 16;

export const ASSOCIATED_COLLEGES_LABELS: Record<number, string> = {
  1: 'North East Learning Trust',
  2: 'Outwood Grange Academies Trust',
  3: 'David Ross Education Trust',
  4: 'The Sea View Trust',
  5: 'Star Academies',
  6: 'The Harris Federation',
  7: 'South West Institute for Teaching',
  8: 'One Cornwall Teaching School Hub',
  9: 'Oasis Community Learning',
  10: '10',
  11: 'Laurus Institute',
  12: 'The Rivers Teaching Alliance',
  13: 'The White Horse Federation',
  14: 'Windsor Academy Trust',
  15: 'Learn - AT',
  16: 'Unity Schools Partnership'
};
